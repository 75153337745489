import { template as template_087993fa82e140849c9dfebaabd5f3b3 } from "@ember/template-compiler";
const FKLabel = template_087993fa82e140849c9dfebaabd5f3b3(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
