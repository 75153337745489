import { template as template_2394535f72184c62bd5c130002d36e08 } from "@ember/template-compiler";
const FKText = template_2394535f72184c62bd5c130002d36e08(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
